import React from 'react'
import SEO from '../components/seo/seo'
import ServiceRows from '../components/rows/serviceRows'
import Hero from '../components/hero/hero'


const PracticeAreasPage = () => (
  <>
    <SEO
      title="Home"
      description="Claremont NH Attorney James G. Feleen is a general practice lawyer, providing real estate, personal injury, and estate planning. 603-504-6507"
      keywords={['law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'real estate', 'probate administration', 'probate', 'personal injury', 'injury', 'wills', 'estate planning', 'title company', 'general practice', 'accident', 'insurance claim', 'negligence', 'medical malpractice', 'work injuries']} />
    <Hero />
    <div className="body-wrapper service-header">
      <p>
      The Law Office of James G. Feleen is a general practice law firm with specialized emphasis in several areas. Attorney Feleen, a 2004 Claremont, NH Chamber of Commerce Citizen of the Year, has been admitted to practice law in NH for over 30 years.
      </p>
      <h2>Practice Areas</h2>
    </div>
    <ServiceRows />
  </>
)

export default PracticeAreasPage
