import { StaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import { IconContext } from "react-icons";
import { GiHouse, GiPapers, GiFamilyHouse, GiBookshelf, GiAmbulance } from 'react-icons/gi';

import './Rows.css'

const serviceRows = props => (
  <StaticQuery
    query={query}
    render={data => (
      <IconContext.Provider value={{ color: "#781010", className: "icon" }}>
        <div className="body-wrapper">
          <div className="row">
            <Img alt="Book shelf full of legal books" className="row-image" fluid={data.imageFive.childImageSharp.fluid}/>
            <div className="row-content">
              <h3>General Practice Law</h3>
              <GiBookshelf />
              <p className="row-text">
              The Law Office of James G. Feleen represents people and businesses for a range of general practice legal needs. Through relationships developed over the years, our office can bring any necessary level of expertise to the table on behalf of our legal clients. Learn more about Feleen’s general law practice services.
              </p>
              <div className="link-wrapper"><Link to="/general-practice-law/">ABOUT GENERAL PRACTICE</Link></div>
            </div>
          </div>
          <div className="row">
            <Img alt="Car that has been damaged in an accident" className="row-image" fluid={data.imageSix.childImageSharp.fluid}/>
            <div className="row-content">
              <h3>Personal Injury Law</h3>
              <GiAmbulance/>
              <p className="row-text">
              We can help you with personal injury law including vehicle accidents, insurance claims, negligence cases, medical malpractice and work injuries. Click the link below to learn more about working with one of the most trusted personal injury attorneys in Sullivan County.
              </p>
              <div className="link-wrapper"><Link to="/personal-injury/">ABOUT PERSONAL INJURY LAW </Link></div>
            </div>
          </div>
          <div className="row">
            <Img alt="Last will and testament document" className="row-image" fluid={data.imageThree.childImageSharp.fluid}/>
            <div className="row-content">
              <h3>Estate Planning & Wills</h3>
              <GiFamilyHouse />
              <p className="row-text">
              A well drafted estate plan or living will are important legal matters. Wills, trusts, powers of attorney, advance directives and wealth protection tailored to the needs of individuals and families are just some of the services we offer. Click the link below to learn more about estate planning.
              </p>
              <div className="link-wrapper"><Link to="/estate-planning/">ABOUT ESTATE PLANNING</Link></div>
            </div>
          </div>
          <div className="row">
            <Img alt="House with lights on" className="row-image" fluid={data.imageOne.childImageSharp.fluid}/>
            <div className="row-content">
              <h3>Real Estate Titles and Law</h3>
              <GiHouse />
              <p className="row-text">
              Reliable and affordable legal representation for buyers, sellers, lenders or developers in all types of residential and commercial transactions. Click the link below to learn more about our real estate law services and our local title company serving Claremont NH and Sullivan County.
              </p>
              <div className="link-wrapper"><Link to="/real-estate-title-law/">ABOUT REAL ESTATE LAW</Link></div>
            </div>
          </div>
          <div className="row last">
            <Img alt="Legal document and pen" className="row-image" fluid={data.imageTwo.childImageSharp.fluid}/>
            <div className="row-content">
              <h3>Probate Administration</h3>
              <GiPapers />
              <p className="row-text">
              The death or incapacity of a loved one is a traumatic, life changing event. The involvement of a skilled and experienced attorney is critical in these times of family stress. We provide experienced and efficient handling of estates and guardianships through the court supervised administration process. Click the link below to learn more our probate administration services.
              </p>
              <div className="link-wrapper"><Link to="/probate-administration/">ABOUT PROBATE ADMINISTRATION</Link></div>
            </div>
          </div>
        </div>
      </IconContext.Provider>
    )}
  />
);

const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "house.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "contract.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "will.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    imageFive: file(relativePath: { eq: "bookcase.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    imageSix: file(relativePath: { eq: "crashed-car.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default serviceRows;


